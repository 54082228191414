import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Layout from "../components/layout"
import '../layouts/index.css'

const BlogPost = ({node}) => {
  return (
    <li>
<img src={node.image.resize.src} alt={node.image.id}/>
      <Link to={node.slug} key={node.id}><h3>{node.title}</h3></Link>
      
      <div key={node.slug} key={node.title}>{node.content.childMarkdownRemark.excerpt}</div>
     
    </li>
  )
}
const ServicesPage = ({data}) => (
    <Layout> <ul className='blog-post'>
    {data.allContentfulBlog.edges.map((edge) => <BlogPost node={edge.node} />)}
  </ul></Layout>
)

export default ServicesPage

export const pageQuery = graphql`
   query pageQuery {
    allContentfulBlog (
    filter: {
      node_locale: {eq: "en-US"}
    }

    ) {
        edges {
          node {
            id
            title
            slug
            content {
              childMarkdownRemark {
                excerpt
              }
            }
            image {
              id
              resize(width:200,height:200) {
                src
              }
             }
          }
        }
    }
   }
`